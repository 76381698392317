import { Icon } from '@coolfire/icons';
import { Button } from '@coolfire/ui';
import type { LoaderFunctionArgs } from '@remix-run/node';
import { redirect } from '@remix-run/node';
import { Form, Link, useLoaderData } from '@remix-run/react';
import { route } from 'routes-gen';
import { requireAuth } from '~/auth/auth.server';
import { DefaultErrorBoundary } from '~/components/sentry-error-boundary';
import { userSessionWorkspace } from '../cookies';
import { getServerFeatureFlags } from '../domains/feature-flags.server';

const noWorkspaceMessage = 'You do not have access to any workspaces';
const createWorkspaceMessage =
	'Get started by creating your first workspace and unlocking the power of Core';

export async function loader({ context, request }: LoaderFunctionArgs) {
	const account = await requireAuth({
		request: request,
		context: context,
	});
	const url = new URL(request.url);
	const { workspaceCreation } = await getServerFeatureFlags(context);

	const workspaces = await account.getWorkspaces();

	// if the user is apart of 0 workspaces return the error boundary
	if (workspaces.length === 0) {
		return {
			message: workspaceCreation ? createWorkspaceMessage : noWorkspaceMessage,
			workspaceCreationEnabled: workspaceCreation,
		};
	}

	const cookieHeader = request.headers.get('Cookie');
	const workspaceCookie = (await userSessionWorkspace.parse(cookieHeader)) || {};
	const { workspaceSlug } = workspaceCookie;
	if (workspaces.some((workspace) => workspace.slug === workspaceSlug)) {
		return redirect(route('/:workspaceSlug', { workspaceSlug: workspaceSlug }));
	}
	if (workspaces.some((workspace) => workspace.slug === url.hostname)) {
		return redirect(route('/:workspaceSlug', { workspaceSlug: url.hostname }));
	}
	return redirect(route('/:workspaceSlug', { workspaceSlug: workspaces[0].slug }));
}

export default function UserDoesNotHaveAWorkspace() {
	const { message, workspaceCreationEnabled } = useLoaderData<typeof loader>();

	return (
		<div className="flex h-full items-center justify-center bg-gray-200/75 pt-8">
			<div className="flex w-4/5 max-w-3xl flex-col items-center space-y-6 rounded-2xl bg-white p-12 shadow-sm">
				<div className="flex flex-col items-center justify-center gap-1">
					<Icon id={'outline:waving-hand'} className="h-20 w-20 text-gray-500" />
					<h1 className="text-center text-2xl font-medium text-gray-800">Welcome!</h1>
					<p className="text-center text-gray-500">{message}</p>
				</div>
				{workspaceCreationEnabled && (
					<Link to={route('/create')} className="text-center">
						<Button variant="secondary">
							<span className="w-24">Create</span>
						</Button>
					</Link>
				)}
				<Form action={route('/logout')} method="post">
					<Button variant="normal" type="submit">
						Log out
					</Button>
				</Form>
			</div>
		</div>
	);
}

export function ErrorBoundary() {
	return (
		<DefaultErrorBoundary>
			<div className="flex h-full items-center justify-center bg-gray-900/75 pt-8">
				<div className="flex w-full max-w-lg flex-col items-center space-y-6 rounded-2xl bg-white p-12 shadow-lg">
					<div className="flex flex-col items-center justify-center gap-1">
						<Icon id={'outline:sentiment-dissatisfied'} className="h-40 w-40 text-blue-600" />
						<h1 className="text-center text-2xl font-medium">We're not sure how you got here</h1>
					</div>
					<Form action={route('/logout')} method="post">
						<button className="text-blue-600 underline" type="submit">
							or log out
						</button>
					</Form>
				</div>
			</div>
		</DefaultErrorBoundary>
	);
}
